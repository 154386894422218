import { styled } from '@/src/stitches.config'
import handleEnterPressEvent from './handleEnterPressEvent'

const FaqStyledWrapper = styled('p', {
    fontSize: '20px',
    textWrap: 'balance',
    '@md': { textWrap: 'pretty' },
})
const FaqStyledButton = styled('button', {
    textDecoration: 'underline',
    color: '$primary',
    backgroundColor: 'none',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    background: 'none',
    fontFamily: 'inherit',
    fontSize: '20px',
    '@md': {
        marginLeft: '-3px',
    },
})
//want to verify the correct toggle switch is set on answer?
function handleChatBot() {
    try {
        // @ts-ignore
        window.adaEmbed.toggle()
    } catch {
        console.log('Unable to open ADA chat window')
    }
}
export const IncludeAdaText = () => {
    return (
        <FaqStyledWrapper>
            For further assistance, click to speak to our{' '}
            <FaqStyledButton
                data-testid={'launch-chatbot-trigger'}
                onClick={handleChatBot}
                onKeyDown={(e) =>
                    handleEnterPressEvent(e, () => handleChatBot())
                }
            >
                GiftGuide.
            </FaqStyledButton>
        </FaqStyledWrapper>
    )
}
